import BaseServices from "./BaseServices";

const PlatformServices = {
    PlatformStoresUser: async (platforms, status, productId = 0) => {
        return await BaseServices.Post(`/api/connectPlatform/get-stores-platform/${productId}?status=${status}`, platforms);
    },
    DeleteConnectStore: async (id) => {
        return await BaseServices.Post(`/api/connectPlatform/delete-connect-store/${id}`);
    },
    GetWebhooksUrl: async (platform) => {
        return await BaseServices.Get(`/api/connectPlatform/get-webhooks-url?platform=${platform}`);
    },
    //lấy danh sách ngành hàng theo platform
    GetCategories: async (paging) => {
        return await BaseServices.Post("/api/Category/get-list-parent-category", paging);
    },
    //sync sản phẩm trên platform về FIFO
    SyncProductsToFiFo: async (model) => {
        return await BaseServices.Post("/api/agencyproducts/sync-products", model);
    },
    //sync sản phẩm trên platform về FIFO
    CheckAdminConnectPlatform: async (platform) => {
        return await BaseServices.Post("/api/connectPlatform/check-connect-platform?platform=" + platform, null);
    },
    ConnectToPlatform: async (param) => {
        return await BaseServices.Get("/api/connectPlatform/connect-to-platform" + (param ? param : ""));
    },
    ConnectToSendo: async (obj) => {
        return await BaseServices.Post("/api/connectPlatform/connect-to-sendo", obj);
    },
    RefreshAccessToken: async (model) => {
        return await BaseServices.Post(`/api/connectPlatform/refresh-access-token`, model);
    },
    //sync thuộc tính
    SyncAttributes: async (model) => {
        return await BaseServices.Post("/api/connectPlatform/sync-attributes/", model);
    },
    TopPickShopee: async () => {
        return await BaseServices.Get("/api/connectPlatform/top-pick-shopee");
    },
    CreateUpdatePlatformCatStore: async (model) => {
        return await BaseServices.Post("/api/platform/create-update-platform-cat-store", model);
    },
    RemovePlatformCatStore: async (id) => {
        return await BaseServices.Get("/api/platform/remove-platform-cat-store/" + id);
    },
    GetPlatformCatStore: async (model) => {
        return await BaseServices.Post("/api/platform/get-platform-cat-store", model);
    },
    GetStoreInfo: async (model) => {
        return await BaseServices.Post("/api/platform/get-store-info", model);
    },
    GetShopCats: async (model) => {
        return await BaseServices.Post("/api/platform/get-shop-cats", model);
    },
    GetShopCatByParentId: async (id) => {
        return await BaseServices.Get("/api/platform/get-shop-cat-by-parent-id/" + id);
    },
    ToggleShopCat: async (id, isActive, shopId = "", platform = 0) => {
        return await BaseServices.Get(`/api/platform/toggle-shop-cat/${id}/${isActive}` + `?shopId=${shopId}&platform=${platform}`);
    },
    RemoveShopCat: async (id, shopId = "", platform = 0) => {
        return await BaseServices.Get(`/api/platform/remove-shop-cat/${id}` + `?shopId=${shopId}&platform=${platform}`);
    },
    CreateUpdateShopCat: async (model) => {
        return await BaseServices.Post("/api/platform/create-update-shop-cat", model);
    },
    GetShopCatById: async (id) => {
        return await BaseServices.Get("/api/platform/get-shop-cat-by-id/" + id);
    },
    GetShopCatProductModels: async (model) => {
        return await BaseServices.Post("/api/platform/get-shop-cat-product-models", model);
    },
    AddRangeShopCat: async (listItem, shopId = "", platform = 0) => {
        return await BaseServices.Post("/api/platform/add-range-shop-cat" + `?shopId=${shopId}&platform=${platform}`, listItem);
    },
    GetShopCatProductModelsByShopCatId: async (model) => {
        return await BaseServices.Post("/api/platform/get-shop-cat-product-models-by-shop-cat-id", model);
    },
    RemoveShopCatConnection: async (id, shopId = "", platform = 0) => {
        return await BaseServices.Get(`/api/platform/remove-shop-cat-connection/${id}` + `?shopId=${shopId}&platform=${platform}`);
    },
    GetProductLowCostDiscounts: async (model) => {
        return await BaseServices.Post("/api/platform/get-product-low-cost-discounts", model);
    },
    CreateUpdateProductLowCostDiscount: async (model) => {
        return await BaseServices.Post("/api/platform/create-update-product-low-cost-discount", model);
    },
    GetProductOptions: async () => {
        return await BaseServices.Get("/api/platform/get-product-options");
    },
    SyncCategories: async (model) => {
        return await BaseServices.Post("/api/connectPlatform/sync-categories", model);
    },
    UpdateSizeBoardUrl: async (model) => {
        return await BaseServices.Post("/api/platform/update-size-board-url", model);
    },
    RemoveSizeBoardUrl: async (model) => {
        return await BaseServices.Post("/api/platform/remove-size-board-url", model);
    },
    CreateUpdateTemplate: async (model) => {
        return await BaseServices.Post("/api/platform/create-update-template", model);
    },
    RemoveTemplateDTO: async (id) => {
        return await BaseServices.Get("/api/platform/remove-template-dto/" + id);
    },
    GetTemplateDTOs: async (model) => {
        return await BaseServices.Post("/api/platform/get-template-dtos", model);
    },
    SyncShopCat: async (model) => {
        return await BaseServices.Post("/api/connectPlatform/sync-shop-cat", model);
    },
    GetListPushShop: async (model) => {
        return await BaseServices.Post("/api/platform/get-list-push-shop", model);
    },
    CreateImageShop: async (model) => {
        return await BaseServices.Post("/api/platform/create-image-shop", model);
    },
    RemovePushShop: async (id) => {
        return await BaseServices.Get("/api/platform/remove-push-shop/" + id);
    },
    UpdateImageShop: async (model) => {
        return await BaseServices.Post("/api/platform/update-image-shop", model);
    },
};
export default PlatformServices;
